import React, { useContext, useEffect, useImperativeHandle, useRef } from 'react';
import { Box, Drawer, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { getBackgroundStyle, getOverLayStyle } from '../../CmtHelpers/JssHelper';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import LayoutContext from './LayoutContext/LayoutContext';
import useClasses from 'hook/useClasses';

const CmtSidebar = React.forwardRef(function LayoutSidebar(props, ref) {
  const {
    type,
    children,
    className,
    sidebarWidth,
    miniSidebarWidth,
    actionSidebarWidth,
    drawerBreakPoint,
    actionBar,
    backgroundColor,
    backgroundImage,
    gradientDirection,
    setSideBarWidth,
    setSidebarHeight,
  } = props;
  const { isOpen, setOpen } = useContext(LayoutContext);
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down(drawerBreakPoint));

  const sidebarRef = useRef(null);

  useEffect(() => {
    const handleResize = () => {
      if (sidebarRef.current) {
        const newHeight = sidebarRef.current.offsetHeight;

        setSidebarHeight(newHeight);
      }
    };
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const updateSidebarWidth = () => {
    if (sidebarRef.current) {
      const { width } = sidebarRef.current.getBoundingClientRect();
      setSideBarWidth(width);
    }
  };

  useEffect(() => {
    // 초기 width 값 설정
    updateSidebarWidth();

    // resize 이벤트를 구독하여 크기 변화 감지
    window.addEventListener('resize', updateSidebarWidth);
    return () => {
      window.removeEventListener('resize', updateSidebarWidth);
    };
  }, []);

  const backgroundStyles = getBackgroundStyle(backgroundColor, backgroundImage, gradientDirection);
  const overlayStyles = getOverLayStyle({
    colors: backgroundColor,
    opacity: 0.8,
    direction: '180deg',
  });

  useImperativeHandle(ref, () => ({
    sidebarType: type,
    drawerBreakPoint,
  }));

  const contentRef = React.createRef();

  const classes = useClasses(styles(theme, sidebarWidth, miniSidebarWidth, actionSidebarWidth));

  const getSidebarContent = () => {
    return (
      <Box ref={contentRef} className={clsx(classes.appSidebar, 'Cmt-sidebar', className)}>
        <Box ref={sidebarRef} className={clsx(classes.appSidebarContent, 'Cmt-sidebar-content')} style={backgroundStyles}>
          {children}
          {overlayStyles && backgroundImage && (
            <Box className={clsx(classes.overlayRoot, 'Cmt-Drawer-overlay')} style={overlayStyles} />
          )}
        </Box>
      </Box>
    );
  };

  const getDrawerContent = () => {
    return (
      <Drawer
        variant="temporary"
        open={isOpen}
        onClose={() => setOpen(false)}
        classes={{
          paper: 'Cmt-drawer-sidebar',
        }}>
        {getSidebarContent()}
      </Drawer>
    );
  };

  if (type === 'drawer' || matches) {
    return actionBar ? (
      <React.Fragment>
        {actionBar}
        {getDrawerContent()}
      </React.Fragment>
    ) : (
      getDrawerContent()
    );
  } else {
    return actionBar ? (
      <Box className={clsx(classes.actionSidebarWrapper, 'Cmt-actionSidebarWrapper')}>
        {actionBar}
        {getSidebarContent()}
      </Box>
    ) : (
      getSidebarContent()
    );
  }
});

CmtSidebar.propTypes = {
  type: PropTypes.oneOf(['full', 'mini', 'drawer']),
  drawerBreakPoint: PropTypes.oneOf(['xs', 'sm', 'md']),
  className: PropTypes.string,
  sidebarWidth: PropTypes.number,
  miniSidebarWidth: PropTypes.number,
  actionSidebarWidth: PropTypes.number,
  actionBar: PropTypes.element,
  backgroundColor: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
  backgroundImage: PropTypes.string,
  gradientDirection: PropTypes.string,
};

CmtSidebar.defaultProps = {
  type: 'full',
  sidebarWidth: 304,
  miniSidebarWidth: 80,
  drawerBreakPoint: 'sm',
  actionSidebarWidth: 72,
  name: 'LayoutSidebar',
};

export default CmtSidebar;

const styles = (theme, sidebarWidth, miniSidebarWidth, actionSidebarWidth) => ({
  appSidebar: {
    display: 'flex',
    flexDirection: 'column',
    minWidth: sidebarWidth,
    height: '100%',
    boxSizing: 'border-box',
    transition: 'all 0.3s ease',
    position: 'relative',
    zIndex: 1103,
    whiteSpace: 'pre-line',
  },
  appSidebarContent: {
    height: '100%',
    position: 'relative',
    transition: 'all 0.3s ease',
    backgroundColor: theme.palette.sidebar.bgColor,
    color: theme.palette.sidebar.textColor,
    overflow: 'hidden',
    '.Cmt-miniLayout &': {
      width: miniSidebarWidth,
      '&:hover': {
        width: sidebarWidth,
      },
    },
    '& > *': {
      position: 'relative',
      zIndex: 3,
    },
  },
  actionSidebarWrapper: {
    display: 'flex',
    width: sidebarWidth + actionSidebarWidth,
    transition: 'all 0.3s ease',

    '& .actionSidebar': {
      width: actionSidebarWidth,
    },
  },
  overlayRoot: {
    position: 'absolute',
    left: 0,
    top: 0,
    zIndex: 0,
    width: '100%',
    height: '100%',
  },
});
