import {
  FETCH_ERROR,
  FETCH_START,
  FETCH_SUCCESS,
  FETCH_STORE_SELECT,
  FETCH_FLOORID_SELECT,
  UPDATE_TIMER_COUNT,
  RESULT_STATUS_PERIOD_SELECT,
  UPDATE_TIMER_HOUR,
  UPDATE_TIMER_MINUTE,
  FETCH_STORENAME_SELECT,
  FETCH_LIDAR_TOKEN,
  UPDATE_SELECTED_MONTH,
  FETCH_URL_MOVE,
  FETCH_PERFORMANCE_PERIOD,
  UPDATE_SELECTED_DATE,
  UPDATE_SELECTED_DATE_RANGE,
  UPDATE_SELECTED_TIME,
  FETCH_PAGE,
  FETCH_LOCALE,
} from '../../@jumbo/constants/ActionTypes';

export const fetchSuccess = (message) => {
  return (dispatch) => {
    dispatch({
      type: FETCH_SUCCESS,
      payload: message || '',
    });
  };
};
export const fetchError = (error) => {
  return (dispatch) => {
    dispatch({
      type: FETCH_ERROR,
      payload: error,
    });
  };
};

export const fetchStart = () => {
  return (dispatch) => {
    dispatch({
      type: FETCH_START,
    });
  };
};

export const storeSelect = (storeCode) => {
  return (dispatch) => {
    dispatch({
      type: FETCH_STORE_SELECT,
      payload: storeCode,
    });
  };
};

export const floorIdSelect = (floorId) => {
  return (dispatch) => {
    dispatch({
      type: FETCH_FLOORID_SELECT,
      payload: floorId,
    });
  };
};

export const updateTimerCount = (state) => {
  return (dispatch) => {
    dispatch({
      type: UPDATE_TIMER_COUNT,
      payload: state,
    });
  };
};

export const resultStatusPeriod = (period) => {
  return (dispatch) => {
    dispatch({
      type: RESULT_STATUS_PERIOD_SELECT,
      payload: period,
    });
  };
};

export const updateSelectedDate = (state) => {
  return (dispatch) => {
    dispatch({
      type: UPDATE_SELECTED_DATE,
      payload: state,
    });
  };
};

export const updateSelectedDateRange = (period) => {
  return (dispatch) => {
    dispatch({
      type: UPDATE_SELECTED_DATE_RANGE,
      payload: period,
    });
  };
};

export const updateSelectedMonth = (period) => {
  return (dispatch) => {
    dispatch({
      type: UPDATE_SELECTED_MONTH,
      payload: period,
    });
  };
};

export const updateSelectedTime = (period) => {
  return (dispatch) => {
    dispatch({
      type: UPDATE_SELECTED_TIME,
      payload: period,
    });
  };
};

export const updateTimerHour = (state) => {
  return (dispatch) => {
    dispatch({
      type: UPDATE_TIMER_HOUR,
      payload: state,
    });
  };
};

export const updateTimerMinute = (state) => {
  return (dispatch) => {
    dispatch({
      type: UPDATE_TIMER_MINUTE,
      payload: state,
    });
  };
};

export const storeNameSelect = (storeName) => {
  return (dispatch) => {
    dispatch({
      type: FETCH_STORENAME_SELECT,
      payload: storeName,
    });
  };
};

export const storeLidarTokenSelect = (lidarToken) => {
  return (dispatch) => {
    dispatch({
      type: FETCH_LIDAR_TOKEN,
      payload: lidarToken,
    });
  };
};

export const fetchUrlMove = (address) => {
  return (dispatch) => {
    dispatch({
      type: FETCH_URL_MOVE,
      payload: address,
    });
  };
};

export const fetchPerformancePeriod = (period) => {
  return (dispatch) => {
    dispatch({
      type: FETCH_PERFORMANCE_PERIOD,
      payload: period,
    });
  };
};

export const fetchPage = (page) => {
  return (dispatch) => {
    dispatch({
      type: FETCH_PAGE,
      payload: page,
    });
  };
};

export const fetchLocale = (locale) => {
  return (dispatch) => {
    dispatch({
      type: FETCH_LOCALE,
      payload: locale,
    });
  };
};
