import React from 'react';
import { SvgServiceReady } from './svg';
import { useSelector } from 'react-redux';
import { isNull } from 'lodash';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import Box from '@mui/material/Box';
import theme from 'theme/theme';
import { useIntl } from 'react-intl';

const Error404 = ({ error }) => {
  const { authUser } = useSelector(({ auth }) => auth);
  const { formatMessage } = useIntl();
  const unavailableMessage = formatMessage({ id: 'page.unavailableMessage' });
  const navigate = useNavigate();

  if (isNull(authUser)) {
    navigate('/signin');
    return null;
  }

  return (
    <StyledContainer>
      <SvgServiceReady style={{ marginBottom: '40px' }} />
      {error ? (
        <StyledErrorMsg>
          페이지 <strong>오류</strong> 안내
        </StyledErrorMsg>
      ) : (
        <StyledErrorMsg>
          <p
            dangerouslySetInnerHTML={{
              __html: unavailableMessage,
            }}
          />
        </StyledErrorMsg>
      )}
      <StyledErrorMsg className="smallErrorMsg">
        {error
          ? '새로고침을 하거나 잠시 후에 다시 접속해 주시기 바랍니다.'
          : formatMessage({ id: 'page.comingSoonMessage' })}
      </StyledErrorMsg>
    </StyledContainer>
  );
};

export default Error404;

const StyledContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
`;

const StyledErrorMsg = styled.span`
  font-size: 42px;

  > strong {
    font-weight: 800;
  }

  &.smallErrorMsg {
    margin-top: 12px;
    color: ${theme.color.darkGray};
    font-size: 22px;
  }
`;
