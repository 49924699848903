import {
  SESSIONSTORAGE_KEY_PeriodPerformance,
  SESSIONSTORAGE_KEY_PeriodResult,
  STR_compareEndDate,
  STR_compareStartDate,
  STR_endDate,
  STR_startDate,
} from '@bis/constants/constStrings';
import { ENUM_PAGE } from '@bis/constants/enums';
import {
  FETCH_ERROR,
  FETCH_START,
  FETCH_SUCCESS,
  FETCH_STORE_SELECT,
  FETCH_FLOORID_SELECT,
  UPDATE_TIMER_COUNT,
  UPDATE_SELECTED_MONTH,
  RESULT_STATUS_PERIOD_SELECT,
  UPDATE_TIMER_HOUR,
  UPDATE_TIMER_MINUTE,
  FETCH_STORENAME_SELECT,
  FETCH_LIDAR_TOKEN,
  FETCH_URL_MOVE,
  FETCH_PERFORMANCE_PERIOD,
  UPDATE_SELECTED_DATE,
  UPDATE_SELECTED_DATE_RANGE,
  UPDATE_SELECTED_TIME,
  FETCH_PAGE,
  FETCH_LOCALE,
} from '@jumbo/constants/ActionTypes';
import { addDays, addMonths, endOfDay, endOfMonth, format, isFirstDayOfMonth, startOfMonth, subDays } from 'date-fns';
import { getDiffdays } from 'utils/dateHelper';

const startDate = isFirstDayOfMonth(new Date()) ? startOfMonth(addMonths(new Date(), -1)) : startOfMonth(new Date());
const endDate = isFirstDayOfMonth(new Date()) ? endOfMonth(addMonths(new Date(), -1)) : endOfDay(addDays(new Date(), -1));
const maxDate = subDays(new Date(), 1);

const checkStorageResultDate = () => {
  const sessionRead = sessionStorage.getItem(SESSIONSTORAGE_KEY_PeriodResult);
  const parseJson = JSON.parse(sessionRead);

  if (sessionRead && parseJson.hasOwnProperty(STR_startDate) && parseJson.hasOwnProperty(STR_endDate)) {
    return { startDate: parseJson.startDate, endDate: parseJson.endDate };
  }
  return { startDate: format(startDate, 'yyyy-MM-dd'), endDate: format(endDate, 'yyyy-MM-dd') };
};

const checkStoragePerformanceDate = () => {
  const sessionRead = sessionStorage.getItem(SESSIONSTORAGE_KEY_PeriodPerformance);
  if (sessionRead) {
    const parseJson = JSON.parse(sessionRead);
    if (
      parseJson.hasOwnProperty(STR_startDate) &&
      parseJson.hasOwnProperty(STR_endDate) &&
      parseJson.hasOwnProperty(STR_compareStartDate) &&
      parseJson.hasOwnProperty(STR_compareEndDate)
    ) {
      return {
        startDate: parseJson.startDate,
        endDate: parseJson.endDate,
        compareStartDate: parseJson.compareStartDate,
        compareEndDate: parseJson.compareEndDate,
      };
    }
  }

  // 스토리지에 없다면 초기값 넣기
  const diff = getDiffdays(startDate, endDate);
  const compareEndDay = addDays(new Date(startDate), -1);
  const compareStartDay = addDays(new Date(compareEndDay), -diff);

  return {
    startDate: format(startDate, 'yyyy-MM-dd'),
    endDate: format(endDate, 'yyyy-MM-dd'),
    compareStartDate: format(compareStartDay, 'yyyy-MM-dd'),
    compareEndDate: format(compareEndDay, 'yyyy-MM-dd'),
  };
};

const dateRangeInitState = (startDate, endDate) => {
  return {
    startDate: format(startDate, 'yyyy-MM-dd'),
    endDate: format(endDate, 'yyyy-MM-dd'),
  };
};

const INIT_STATE = {
  initialURL: '/',
  error: '',
  message: '',
  loading: false,
  selectedStoreCode: null,
  selectedFloorId: null,
  updateTimerCount: 0,
  selectedResultStatusPeriod: checkStorageResultDate(),
  updateTimerHour: 0,
  updateTimerMinute: 0,
  selectedStoreName: null,
  selectedLidarToken: null,
  selectedMonth: null,
  selectedTime: null,
  selectedDate: new Date(),
  selectedDateRange: dateRangeInitState(subDays(maxDate, 28), maxDate),
  urlMove: '/',
  page: ENUM_PAGE.BIS,
  locale: 'ko',
  performancePeriod: checkStoragePerformanceDate(),
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case FETCH_START: {
      return { ...state, error: '', message: '', loading: true };
    }
    case FETCH_SUCCESS: {
      return { ...state, error: '', loading: false, message: action.payload };
    }
    case FETCH_ERROR: {
      return { ...state, loading: false, message: '', error: action.payload };
    }
    case FETCH_STORE_SELECT: {
      return { ...state, selectedStoreCode: action.payload };
    }
    case FETCH_FLOORID_SELECT: {
      return { ...state, selectedFloorId: action.payload };
    }
    case UPDATE_TIMER_COUNT: {
      return { ...state, updateTimerCount: action.payload };
    }
    case RESULT_STATUS_PERIOD_SELECT: {
      return { ...state, selectedResultStatusPeriod: action.payload };
    }
    case UPDATE_SELECTED_MONTH: {
      return { ...state, selectedMonth: action.payload };
    }
    case UPDATE_SELECTED_TIME: {
      return { ...state, selectedTime: action.payload };
    }
    case UPDATE_SELECTED_DATE: {
      return { ...state, selectedDate: action.payload };
    }
    case UPDATE_SELECTED_DATE_RANGE: {
      return { ...state, selectedDateRange: action.payload };
    }
    case UPDATE_TIMER_HOUR: {
      return { ...state, updateTimerHour: action.payload };
    }
    case UPDATE_TIMER_MINUTE: {
      return { ...state, updateTimerMinute: action.payload };
    }
    case FETCH_STORENAME_SELECT: {
      return { ...state, selectedStoreName: action.payload };
    }
    case FETCH_LIDAR_TOKEN: {
      return { ...state, selectedLidarToken: action.payload };
    }
    case FETCH_URL_MOVE: {
      return { ...state, urlMove: action.payload };
    }
    case FETCH_PAGE: {
      return { ...state, page: action.payload };
    }
    case FETCH_LOCALE: {
      return { ...state, locale: action.payload };
    }
    case FETCH_PERFORMANCE_PERIOD: {
      return { ...state, performancePeriod: action.payload };
    }
    default:
      return state;
  }
};
