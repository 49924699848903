import { CFN_addComma, CFN_ratio, CFN_TOFIXED } from 'utils/commFunction';
import { FormatMsg } from 'utils/FormatMsg';

/**
 * 월별 분석
 * 지난달 대비, 이전 3개월 평균 대비, 전년 동월 대비
 */
export const MONTHLY_TABLE_LIST = (item, unit = '') => {
  const currency = unit === 'unit.currency';

  return [
    {
      title: FormatMsg('cards.content.contrastByPastMonth'),
      value: FormatMsg(unit, { num: CFN_addComma(CFN_TOFIXED(item.prevMonth.value, currency ? 0 : 1)) }),
      ratio: item.prevMonth.rateOfChange ? CFN_ratio(CFN_TOFIXED(item.prevMonth.rateOfChange, 1)) : null,
    },
    {
      title: FormatMsg('cards.content.prev3mAvgDiff'),
      value: FormatMsg(unit, { num: CFN_addComma(CFN_TOFIXED(item.prevQuarter.value, currency ? 0 : 1)) }),
      ratio: item.prevQuarter.rateOfChange ? CFN_ratio(CFN_TOFIXED(item.prevQuarter.rateOfChange, 1)) : null,
    },
    {
      title: FormatMsg('cards.content.yearOverYear'),
      value: FormatMsg(unit, { num: CFN_addComma(CFN_TOFIXED(item.prevYear.value, currency ? 0 : 1)) }),
      ratio: item.prevYear.rateOfChange ? CFN_ratio(CFN_TOFIXED(item.prevYear.rateOfChange, 1)) : null,
    },
  ];
};

/*
 * 구독 서비스 적용 페이지
 */
export const SUBSCRIPTION_PAGE = ['raw-material-status-forecast', 'usp-shipping'];
