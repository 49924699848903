//----
export const FETCH_STORE_SELECT = 'fetch_store_select';
export const FETCH_FLOORID_SELECT = 'fetch_floorid_select';
export const UPDATE_TIMER_COUNT = 'update_timer_count';
export const RESULT_STATUS_PERIOD_SELECT = 'result_status_period_select';
export const UPDATE_TIMER_HOUR = 'update_timer_hour';
export const UPDATE_TIMER_MINUTE = 'update_timer_minute';
export const FETCH_STORENAME_SELECT = 'fetch_storename_select';
export const UPDATE_SELECTED_MONTH = 'update_selected_month';
export const UPDATE_SELECTED_TIME = 'update_selected_time';
export const UPDATE_SELECTED_DATE = 'update_selected_date';
export const UPDATE_SELECTED_DATE_RANGE = 'update_selected_date_range';
export const FETCH_URL_MOVE = 'fetch_url_move';
export const FETCH_PAGE = 'fetch_page';
export const FETCH_PERFORMANCE_PERIOD = 'fetch_performance_period';
export const FETCH_LOCALE = 'fetch_locale';
//----

//lidar token
export const FETCH_LIDAR_TOKEN = 'fetch_lidar_token';

///
export const SHOW_MESSAGE = 'show_message';
export const HIDE_MESSAGE = 'hide_message';
export const FETCH_START = 'fetch_start';
export const FETCH_SUCCESS = 'fetch_success';
export const FETCH_ERROR = 'fetch_error';

export const UPDATE_AUTH_USER = 'update_auth_user';
export const UPDATE_LOAD_USER = 'update_load_user';
export const SEND_FORGET_PASSWORD_EMAIL = 'send_forget_password_email';
export const SIGNIN_GOOGLE_USER_SUCCESS = 'signin_google_user_success';
export const SIGNIN_FACEBOOK_USER_SUCCESS = 'signin_facebook_user_success';
export const SIGNIN_TWITTER_USER_SUCCESS = 'signin_twitter_user_success';
export const SIGNIN_GITHUB_USER_SUCCESS = 'signin_github_user_SUCCESS';
export const SIGNIN_USER_SUCCESS = 'signin_user_success';
export const SIGNOUT_USER_SUCCESS = 'signout_user_success';

export const SET_DASHBOARD_DATA = 'set_dashboard_data';

export const SET_TASK_CURRENT_USER = 'set_task_current_user';
export const SET_TASKS_DATA = 'set_tasks_data';
export const SET_TASK_LIST_DATA = 'set_task_list_data';
export const ADD_TASK = 'add_task';
export const DELETE_TASK = 'delete_task';
export const UPDATE_TASK = 'update_task';
export const SET_FILTER_DATA = 'set_filter_data';
export const ADD_TASK_LIST = 'add_task_list';
export const UPDATE_TASK_LIST = 'update_task_list';
export const DELETE_TASK_LIST = 'delete_task_list';
export const SET_TASK_DETAIL = 'set_task_detail';
export const SEND_MESSAGE = 'send_message';
export const TOGGLE_SIDEBAR_COLLAPSED = 'toggle_sidebar_collapsed';
export const GET_TASKS_COUNTS = 'get_tasks_counts';

//mail app
export const GET_LABELS_LIST = 'get_labels_list';
export const GET_CONNECTIONS_LIST = 'get_connections_list';
export const GET_MAILS_LIST = 'get_mails_list';
export const UPDATE_MAIL_FOLDER = 'update_mail_folder';
export const UPDATE_MAIL_LABEL = 'upade_mail_label';
export const UPDATE_FAVORITE_STATUS = 'update_favorite_status';
export const UPDATE_READ_STATUS = 'update_read_status';
export const UPDATE_IMPORTANT_STATUS = 'update_important_status';
export const COMPOSE_MAIL = 'compose_mail';
export const SET_FILTER_TYPE = 'set_filter_type';
export const GET_SELECTED_MAIL = 'GET_SELECTED_MAIL';
export const UPDATE_SELECTED_MAIL = 'update_selected_mail';
export const NULLIFY_SELECTED_MAIL = 'nullify_selected_mail';
export const REPLY_TO_MAIL = 'reply_to_mail';
export const GET_MAIL_COUNTS = 'get_mail_count';
export const ADD_LABEL = 'add_label';
export const ADD_CONNECTION = 'add_connection';
export const REMOVE_CONNECTION = 'remove_connection';

export const SET_CHAT_USERS = 'set_chat_users';
export const SET_CONTACT_USERS = 'set_contact_users';
export const SET_CURRENT_USER = 'set_current_user';
export const SET_CONVERSATION_DATA = 'set_conversation_data';
export const SEND_NEW_CHAT_MESSAGE = 'send_new_chat_message';
export const SEND_NEW_MEDIA_MESSAGE = 'send_new_media_message';

//Contact App
export const GET_CONTACTS_LIST = 'get_contacts_list';
export const SET_CURRENT_CONTACT = 'set_current_contact';
export const CREATE_CONTACT = 'create_contact';
export const UPDATE_STARRED_STATUS = 'update_starred_status';
export const DELETE_CONTACT = 'delete_contact';
export const UPDATE_CONTACT_LABEL = 'update_contact_label';
export const UPDATE_CONTACT = 'update_contact';
export const GET_CONTACT_COUNTS = 'get_contact_counts';
export const UPDATE_LABEL_ITEM = 'update_label_item';
export const DELETE_LABEL_ITEM = 'delete_label_item';

export const GET_USER_DETAIL = 'get_user_detail';
export const GET_FEED_POSTS = 'get_feed_posts';
export const CREATE_POST = 'create_post';
export const UPDATE_POST = 'update_post';

// Users Module
export const GET_USERS = 'get_users';
export const ADD_USER = 'add_user';
export const SET_USER_DETAILS = 'set_user_details';
export const EDIT_USER = 'edit_user';
export const DELETE_USER = 'delete_user';
export const DELETE_BULK_USERS = 'delete_bulk_users';

// BeatStores Module
export const GET_BEAT_STORES = 'get_beat_stores';
export const ADD_BEAT_STORE = 'add_beat_store';
export const SET_BEAT_STORE_DETAILS = 'set_beat_store_details';
export const EDIT_BEAT_STORE = 'edit_beat_store';
export const DELETE_BEAT_STORE = 'delete_beat_store';

// RealTime
export const FETCH_VISIT_COUNT_TODAY = 'fetch_visit_count_today';
export const FETCH_VISIT_COUNT_LASTOPENDAY = 'fetch_visit_count_lastopenday';
export const FETCH_VISIT_COUNT_THISMONTH = 'fetch_visit_count_thismonth';
export const FETCH_VISIT_COUNT_LASTOPENMONTH = 'fetch_visit_count_lastopenmonth';
export const FETCH_BEATBOX_LIST = 'fetch_beatbox_list';
export const FETCH_LAST_OPENDAY = 'fetch_last_openday';
export const FETCH_REFRESH_COUNTDOWN_MSEC = 'fetch_refresh_countdown_Millisecond';
export const FETCH_LIST_TODAY_VISIT_BY_BEATBOX = 'fetch_list_today_visit_by_beatbox';
export const FETCH_LIST_THISMONTH_VISIT_BY_BEATBOX = 'fetch_list_thismonth_visit_by_beatbox';

// 실적현황
export const FETCH_LIST_PERIOD_VISIT_BY_BEATBOX = 'fetch_list_period_visit_by_beatbox';
export const FETCH_VISIT_COUNT_PERIOD = 'fetch_visit_count_period';
