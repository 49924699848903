import React, { useEffect } from 'react';
import LanguageIcon from '@mui/icons-material/Language';
import { useDispatch } from 'react-redux';
import { useSessionStorage } from 'react-use';
import { fetchLocale } from 'redux/actions';

export default function LanguageSwitcher() {
  const dispatch = useDispatch();
  const [locale, setLocale] = useSessionStorage('locale', 'ko');

  const handleChangeLocale = () => {
    setLocale(locale === 'ko' ? 'en' : 'ko');
  };

  useEffect(() => {
    dispatch(fetchLocale(locale));
  }, [dispatch, locale]);

  return (
    <LanguageIcon
      onClick={handleChangeLocale}
      fontSize="large"
      sx={{ color: '#fff', marginLeft: '10px', cursor: 'pointer' }}
    />
  );
}
