import React, { useState } from 'react';
import { Autocomplete, Badge, InputAdornment, TextField } from '@mui/material';
import { useIntl } from 'react-intl';
import { isNull } from 'lodash';
import { ENUM_SELECT_STORE } from '@bis/constants/enums';
import { useLocation, useNavigate } from 'react-router';
import { useSelector } from 'react-redux';
import { CFN_isAdmin } from 'utils/commFunction';
import { SUBSCRIPTION_PAGE } from '@bis/constants/constList';
import styled from 'styled-components';
import parse from 'autosuggest-highlight/parse';
import match from 'autosuggest-highlight/match';
import useClasses from 'hook/useClasses';
import SearchIcon from '@mui/icons-material/Search';
import CmtDialog from '@coremat/CmtDialog';
import useIncludesPage from 'hook/useIncludesPage';
import theme from 'theme/theme';

const MAX_LENGTH = 100;

const ModuleAutocomplete = ({ value, setValue, options, storeType, storeList }) => {
  const { formatMessage } = useIntl();
  const navigate = useNavigate();
  const location = useLocation();
  const roleName = useSelector((state) => state.auth.authUser?.roleName);
  const styles = useClasses(autocompleteStyles);
  const position = location.pathname.lastIndexOf('/');
  const url = location.pathname.slice(0, position + 1);
  const page = useIncludesPage(SUBSCRIPTION_PAGE);

  const [isOpen, setIsOpen] = useState(false);
  const [inputValue, setInputValue] = useState('');
  const [storeName, setStoreName] = useState('');

  const handleOnChange = (e, newValue) => {
    setStoreName(newValue.name);
    if (CFN_isAdmin(roleName)) {
      setValue(newValue);
      navigate(`${url}${newValue.code}`);
    }

    if (newValue.code !== 'ALL') {
      const subscription = storeList.find((el) => el.code === newValue.code).subscription;
      const { is_experience, product } = subscription;
      const subscriptionService = product === 'PREMIUM' || is_experience;

      if (!subscriptionService && page) {
        setIsOpen(true);
      } else {
        setValue(newValue);
        navigate(`${url}${newValue.code}`);
      }
    } else {
      setValue(newValue);
      navigate(`${url}${newValue.code}`);
    }
  };

  return (
    <>
      <CmtDialog cancle={false} type="confirm" open={isOpen} setOpen={setIsOpen}>
        <div style={{ padding: '0px 34px 0px', textAlign: 'center' }}>
          <p>{formatMessage({ id: 'error.message.subscription' }, { name: storeName })}</p>
        </div>
      </CmtDialog>
      <Autocomplete
        id="select-beatstore"
        fullWidth
        disableClearable={true}
        value={value}
        options={options}
        onChange={handleOnChange}
        inputValue={inputValue === value?.name ? '' : inputValue}
        onInputChange={(event, newInputValue) => {
          setInputValue(newInputValue);
        }}
        getOptionLabel={(option) => option.name || ''}
        getOptionDisabled={(option) => {
          if (storeType === ENUM_SELECT_STORE.STORES_LIDAR) {
            return isNull(option?.floorId);
          }
        }}
        sx={{ width: 500 }}
        classes={{ paper: styles.paper, option: styles.option }}
        loading={true}
        loadingText={formatMessage({
          id: 'manage.users.warning.notFoundStore',
        })}
        isOptionEqualToValue={(option, value) => option.name === value.name}
        renderTags={() => {}} // chips를 안 보이게 할 때
        renderOption={(props, option, { inputValue }) => {
          const removeBlankInputValue = inputValue.replace(/(\s*)/g, ''); // 문자열 모든 공백 제거
          const matches = match(option?.name, removeBlankInputValue);
          const parts = parse(option.name, matches);
          const selectCode = option?.code === value?.code;

          return (
            <li {...props}>
              <StyledBadge anchorOrigin={{ vertical: 'top', horizontal: 'left' }} variant="dot" invisible={!option.floorId}>
                {parts?.map((part) => {
                  const fontWeight = part?.highlight ? 700 : 400;
                  const textColor = part?.highlight ? theme.color.red : selectCode ? '#fff' : '#000';
                  return (
                    <span key={part.text} style={{ fontWeight, color: textColor }}>
                      {part.text}
                    </span>
                  );
                })}
              </StyledBadge>
            </li>
          );
        }}
        renderInput={(params) => (
          <StyledTextField
            {...params}
            hiddenLabel
            placeholder={formatMessage({
              id: 'autocomplete.select.store.placeholder',
            })}
            variant="filled"
            InputProps={{
              ...params.InputProps,
              disableUnderline: true,
              inputProps: { ...params.inputProps, maxLength: MAX_LENGTH },
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
          />
        )}
        ListboxProps={{ style: { maxHeight: 375 } }}
      />
    </>
  );
};

export default ModuleAutocomplete;

const StyledBadge = styled(Badge)(() => ({
  '& .MuiBadge-badge': {
    backgroundColor: theme.color.blue,
    color: theme.color.blue,
    '&::after': {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      borderRadius: '50%',
      animation: 'ripple 1.2s infinite ease-in-out',
      border: '1px solid currentColor',
      content: '""',
    },
  },
  '@keyframes ripple': {
    '0%': {
      transform: 'scale(.8)',
      opacity: 1,
    },
    '100%': {
      transform: 'scale(2.4)',
      opacity: 0,
    },
  },
}));

const StyledTextField = styled(TextField)`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 12px;
  height: 40px;
  background-color: #fff;
  border-radius: 4px;
  & .MuiFilledInput-root {
    background-color: transparent;
  }
`;

const autocompleteStyles = {
  paper: {
    border: '1px solid #000',
  },

  option: {
    '&:hover': {
      backgroundColor: '#EFF7FD !important',
      color: '#000 !important',
    },

    '&[aria-selected="true"]': {
      backgroundColor: '#23275f !important',
      color: '#fff !important',
    },
  },
};
