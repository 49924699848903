import { createTheme } from '@mui/material/styles';
import { Fonts } from '@jumbo/constants/ThemeOptions';
import theme from './theme';

export const muiTheme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1280,
      xl: 1620,
    },
  },
  spacing: 4,
  direction: 'ltr',
  palette: {
    mode: 'light',
    common: {
      black: '#000',
      white: '#fff',
      dark: '#020202',
    },
    primary: {
      main: `${theme.color.blue}`,
      dark: `${theme.color.blue}`,
      contrastText: '#fff',
    },
    secondary: {
      main: '#03dac5',
      dark: '#018786',
      contrastText: '#fff',
    },
    success: {
      light: '#d7f5b1',
      main: '#8dcd03',
      dark: '#5d9405',
    },
    info: {
      light: '#9be7fd',
      main: '#0795f4',
      dark: '#0356af',
    },
    warning: {
      light: '#ffde99',
      main: '#ff8c00',
      dark: '#d36f1a',
    },
    error: {
      light: '#ffc7d1',
      main: theme.color.red,
      dark: '#87061e',
    },
    sidebar: {
      bgColor: '#fff',
      textColor: `${theme.color.pureGray}`,
      textActiveColor: `${theme.color.blue}`,
      navHoverBgColor: '#ecf5fe',
    },
    horizontalNav: {
      navigationColor: 'rgba(255, 255, 255, 0.74)',
      navigationActiveColor: 'rgba(255, 255, 255, 1)',
      textColor: 'rgba(0, 0, 0, 0.6)',
      textDarkColor: 'rgba(0, 0, 0, 0.87)',
      textActiveColor: `${theme.color.blue}`,
      menuHoverBgColor: 'rgb(229, 229, 229)',
      menuActiveBgColor: '#ecf5fe',
    },
    background: {
      paper: '#fff',
      default: '#f5f5f5',
    },
    text: {
      primary: 'rgba(0, 0, 0, 0.87)',
      secondary: 'rgba(0, 0, 0, 0.6)',
      disabled: 'rgba(0, 0, 0, 0.38)',
      hint: 'rgba(0, 0, 0, 0.3)',
      white: '#fff',
    },
    btn: {
      hover: 'rgba(0, 0, 0, 0.08)',
    },
    lightBtn: {
      bgColor: '#f5f5f5',
      textColor: 'rgba(0, 0, 0, 0.38)',
    },
    borderColor: {
      main: 'rgba(0, 0, 0, 0.06)',
      dark: '#e0e0e0',
    },
    popupColor: {
      main: '#fff',
    },
  },
  status: {
    danger: 'orange',
  },
  typography: {
    fontFamily: Fonts.PRIMARY,
    letterSpacing: 19,
    fontWeightExtraLight: 200,
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightBold: 'bold',
    fontWeightExtraBold: 800,
    button: {
      textTransform: 'none',
    },
  },
  components: {
    MuiPaper: {
      styleOverrides: {
        root: {
          borderRadius: 0,
          boxShadow: '2px 2px 4px 0 rgba(0, 0, 0, 0.1)',
          overflow: 'auto',
        },
      },
    },
  },
});
